<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <b-table
      ref="refListTable"
      :items="dataList"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('spot.emptyText')"
      :sort-desc.sync="isSortDirDesc"
      striped
    >

      <template #thead-top>
        <b-tr>
          <b-th colspan="12">
            {{ totalCount }} {{ totalCount > 1 ? $t('spot.vendaAberta.textPlural'): $t('spot.vendaAberta.textSingular') }}
          </b-th>
        </b-tr>
      </template>

      <template #cell(paridade)="data">
        <div class="text-nowrap">
          <b-avatar
            size="sm"
            :src="getIcon(data.item.moedaTrade)"
          />
          <span class="mr-1">
            {{ data.item.moedaTrade }}<span class="font-weight-light">/{{ data.item.paridadeTrade }}</span>
          </span>
        </div>
      </template>

      <template #cell(precoCompra)="data">
        {{ toFixed(data.value, 6) }}
      </template>

      <template #cell(precoVenda)="data">
        <template v-if="data.value">
          <template v-if="data.item.ordemTipo!='MARKET'">
            {{ toFixed(data.value, 6) }} <br>
            <b-badge variant="light-success">
              {{ getPercentDiff(data.item.precoCompra, data.item.precoVenda) }}%
            </b-badge>
          </template>
        </template>
      </template>

      <template #cell(statusWs)="data">
        <template v-if="tickers[data.item.moedaParidade]!=null">
          <div class="text-nowrap">
            <span
              :class="parseFloat(tickers[data.item.moedaParidade].price) >= parseFloat(data.item.precoCompra)? 'text-success' : 'text-danger'"
            >
              {{ percentDiferenca(data.item.precoCompra, tickers[data.item.moedaParidade].price) }}%
            </span>
          </div>
        </template>
        <template v-else>
          <div class="text-center">
            <b-spinner small />
          </div>
        </template>
      </template>

      <template #cell(precoWs)="data">
        <template v-if="tickers[data.item.moedaParidade]!=null">
          {{ tickers[data.item.moedaParidade].price }}
        </template>
        <template v-else>
          <div class="text-center">
            <b-spinner small />
          </div>
        </template>
      </template>

      <template #cell(stopLoss)="data">
        <template v-if="data.item.ordemTipo=='OCO'">
          {{ data.value }} <br>
          <b-badge variant="light-danger">
            {{ getPercentDiff(data.item.precoCompra, data.item.stopLoss) }}%
          </b-badge>
        </template>
      </template>

      <template #cell(total)="data">
        <template v-if="data.value">
          <template v-if="data.item.ordemTipo!='MARKET'">
            {{ toFixed(data.value, 6) }}
          </template>
        </template>
      </template>

      <template #cell(dataCriacao)="data">
        {{ formatDateTimeSemAnoBR(data.value) }}
      </template>

      <template #cell(agente)="data">
        {{ data.value }} <br>
        <b-badge variant="light-primary">
          #{{ data.item.sinalId }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(acao)="data">
        <b-dropdown
          v-if="data.item.ordemTipo!='MARKET'"
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item
            v-if="data.item.ordemTipo!='MARKET'"
            @click.stop="confirmarVenderAgora(data.item.id)"
          >
            <feather-icon icon="TargetIcon" />
            <span class="align-middle ml-50">{{ $t('spot.vendaAberta.venderBtn') }}</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="data.item.ordemTipo=='OCO'"
            @click.stop="confirmarRemoverStop(data.item.id)"
          >
            <feather-icon icon="StopCircleIcon" />
            <span class="align-middle ml-50">{{ $t('spot.vendaAberta.removerBtn') }}</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="data.item.ordemTipo!='MARKET'"
            @click.stop="confirmarCancelarOrdem(data.item.id, data.item.moedaTrade)"
          >
            <feather-icon icon="Trash2Icon" />
            <span class="align-middle ml-50">{{ $t('spot.vendaAberta.cancelarBtn') }}</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="data.item.ordemTipo!='MARKET' && data.item.paridadeTrade=='BUSD' && data.item.moedaTrade!='BTCUP' && data.item.moedaTrade!='BTCDOWN'"
            @click.stop="confirmarConverterUSDT(data.item.id)"
          >
            <feather-icon icon="RepeatIcon" />
            <span class="align-middle ml-50">{{ $t('spot.vendaAberta.converterBtn') }}</span>
          </b-dropdown-item>

        </b-dropdown>

      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('spot.textMuted', { from: paginationMeta.from, to: paginationMeta.to, of: paginationMeta.of }) }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalCount"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>

</template>

<script>

import {
  BCard, BTable, BAvatar, BPagination, BRow, BCol, BSpinner, BBadge, BDropdown, BDropdownItem, BTr, BTh,
} from 'bootstrap-vue'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import { formatDateTimeSemAnoBR, percentDiferenca } from '@/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import vendaAbertaStoreModule from './vendaAbertaStoreModule'

const VENDA_ABERTA_SPOT_STORE_MODULE_NAME = 'venda-aberta-spot'

export default {

  components: {
    BCard,
    BTable,
    BAvatar,
    BPagination,
    BRow,
    BCol,
    BSpinner,
    BBadge,
    BDropdown,
    BDropdownItem,
    BTr,
    BTh,
  },

  mixins: [utilsMixin],

  data() {
    return {
      polling: null,
      formatDateTimeSemAnoBR,
      percentDiferenca,
      perPage: 10,
      totalCount: 0,
      currentPage: 1,
      sortBy: 'id,desc',
      isSortDirDesc: true,
      dataList: [],
    }
  },

  computed: {
    tableColumns() {
      return [
        { key: 'paridade', label: this.$i18n.t('spot.vendaAberta.tableHeader.paridade') },
        { key: 'quantidade', label: this.$i18n.t('spot.vendaAberta.tableHeader.quantidade') },
        { key: 'precoCompra', label: this.$i18n.t('spot.vendaAberta.tableHeader.precoCompra') },
        { key: 'precoWs', label: this.$i18n.t('spot.vendaAberta.tableHeader.precoWs') },
        { key: 'precoVenda', label: this.$i18n.t('spot.vendaAberta.tableHeader.precoVenda') },
        { key: 'statusWs', label: this.$i18n.t('spot.vendaAberta.tableHeader.statusWs') },
        { key: 'stopLoss', label: this.$i18n.t('spot.vendaAberta.tableHeader.stopLoss') },
        { key: 'total', label: this.$i18n.t('spot.vendaAberta.tableHeader.total') },
        { key: 'dataCriacao', label: this.$i18n.t('spot.vendaAberta.tableHeader.dataCriacao') },
        { key: 'agente', label: this.$i18n.t('spot.vendaAberta.tableHeader.agente') },
        { key: 'acao', label: this.$i18n.t('spot.vendaAberta.tableHeader.acao') },
      ]
    },

    tickers() {
      return this.$store.state.binanceSpot.tickers
    },

    paginationMeta() {
      const localItemsCount = this.$refs.refListTable ? this.$refs.refListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalCount,
      }
    },
  },

  watch: {
    currentPage() {
      this.fetchList()
      this.$refs.refListTable.refresh()
    },
  },

  created() {
    if (!store.hasModule(VENDA_ABERTA_SPOT_STORE_MODULE_NAME)) store.registerModule(VENDA_ABERTA_SPOT_STORE_MODULE_NAME, vendaAbertaStoreModule)
    this.pollfetchList()
  },

  beforeDestroy() {
    clearInterval(this.polling)
    if (store.hasModule(VENDA_ABERTA_SPOT_STORE_MODULE_NAME)) store.unregisterModule(VENDA_ABERTA_SPOT_STORE_MODULE_NAME)
  },

  methods: {

    fetchList() {
      store
        .dispatch('venda-aberta-spot/getVendasAbertas', {
          size: this.perPage,
          page: this.currentPage - 1,
          sortBy: this.sortBy,
          sortDesc: this.isSortDirDesc,
          status: 'aberta',
        })
        .then(response => {
          const { content, totalElements } = response.data
          this.dataList = content
          this.totalCount = totalElements
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    // cancelar ordem
    confirmarCancelarOrdem(id, moeda) {
      this.$swal({
        title: 'Tem certeza que deseja cancelar a venda ?',
        text: `Sua ordem de venda será cancelada, a moeda ${moeda} ficará em sua carteira`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, pode cancelar!',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.cancelarOrdem(id)
        }
      })
    },

    cancelarOrdem(id) {
      this.$store.dispatch('venda-aberta-spot/cancelarOrdem', id)
        .then(() => {
          this.fetchList()
          this.$swal({
            icon: 'success',
            title: 'Cancelada!',
            text: 'Sua ordem de venda foi cancelada com sucesso',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
    },

    // vender agora
    confirmarVenderAgora(id) {
      this.$swal({
        title: 'Tem certeza que deseja vender agora?',
        text: 'Sua ordem atual será cancelada e uma nova será aberta para vender pelo preço atual',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, pode vender!',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.venderAgora(id)
        }
      })
    },

    venderAgora(id) {
      this.$store.dispatch('venda-aberta-spot/venderAgora', id)
        .then(() => {
          this.fetchList()
          this.$swal({
            icon: 'success',
            title: 'Encerrando Venda!',
            text: 'Sua ordem de venda está sendo processada',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
    },

    // remover stop
    confirmarRemoverStop(id) {
      this.$swal({
        title: 'Tem certeza que deseja remover o Stop ?',
        text: 'Sua ordem de venda será cancelada e uma nova será aberta sem stop',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, pode remover!',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.removerStop(id)
        }
      })
    },

    removerStop(id) {
      this.$store.dispatch('venda-aberta-spot/removerStop', id)
        .then(() => {
          this.fetchList()
          this.$swal({
            icon: 'success',
            title: 'Removendo Stop!',
            text: 'Sua ordem de venda está sendo processada',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
    },

    // converter USDT
    confirmarConverterUSDT(id) {
      this.$swal({
        title: 'Tem certeza que deseja converter de BUSD para USDT ?',
        text: 'Sua ordem de venda será cancelada e uma nova será aberta',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, pode converter!',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.converterUSDT(id)
        }
      })
    },

    converterUSDT(id) {
      this.$store.dispatch('venda-aberta-spot/converterUSDT', id)
        .then(() => {
          this.fetchList()
          this.$swal({
            icon: 'success',
            title: 'Cancelada!',
            text: 'Sua ordem de venda foi convertida com sucesso',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
    },

    pollfetchList() {
      this.fetchList()
      const self = this
      this.polling = setInterval(() => {
        self.fetchList()
      }, 5000)
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
