import axios from '@axios'
import { subscribeSymbol } from '@/services/binance/spot/binance'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getVendasAbertas(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/ordem-venda/aberta', { params: queryParams })
          .then(response => {
            // assinar as moedas presentes na ordem para receber informações
            response.data.content.forEach(currency => {
              subscribeSymbol(currency.moedaParidade)
            })

            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    cancelarOrdem(ctx, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`ordem-venda/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    venderAgora(ctx, id) {
      return new Promise((resolve, reject) => {
        axios.post(`ordem-venda/vender-agora/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    converterUSDT(ctx, id) {
      return new Promise((resolve, reject) => {
        axios.post(`ordem-venda/converter-usdt/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    removerStop(ctx, id) {
      return new Promise((resolve, reject) => {
        axios.post(`ordem-venda/remover-stop/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
